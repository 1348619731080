<script>
    import V from 'voUtils/V.js'

    export default {
    	voVueComponent: 'feedback-carousel',
    	props: {
    		carouselId: String
    	},

    	mounted: function() {
    		$("#owl-" + this.carouselId).owlCarousel({
    			items: 2,
    			loop: true,
    			autoplay: false,
    			dots: false,
    			smartSpeed: 1000,
    			nav: true,
    			autoplayTimeout: 10000,
    			responsive: {

    				0: {
    					items: 1
    				},

    				768: {
    					items: 1
    				},
    				992: {
    					items: 2
    				}
    			}
    		});
    	}
    };
</script>