import V from 'voUtils/V.js';
import QuoteTools from 'voUtils/QuoteTools.js';

import {
	doReq
}
from 'voUtils/RestEx.js';
import {
	fireQuote, fireUnitAction, fireCheckout
}
from 'voUtils/TagEx.js';
import {
	find, filter, isEmpty, oneIs
}
from 'voUtils/tools.js';

import {
	getVoucher
}
from 'voUtils/VoucherStore.js';

import DateEx from 'voUtils/DateEx.js';

export default {

	voMsgCodes: [
		'tpl.unit.props.show.more',
		'tpl.unit.props.show.less',
	],

	data: {
		bookingData: {
			adults: 2,
			children: 0,
			childrenAges: undefined,
			babys: 0,
			petsCount: 0,
			from: undefined,
			till: undefined
		},
		action: undefined,
		step: undefined, //'booking',
		priceIsUpdated: false,
		showPriceDetails: false,
		quote: {},
		error: undefined,
		preview: undefined,
		summary: undefined,
		selectedImageIndex: undefined,
		showAllProps: false,
		showFullDesc: false,
		showFullTexts: false,
		scrollToPrices: false,
		voucher: getVoucher(),
		showAllFeedback: false,
		selectedTravelInsurance: 0,
		travelInsurancePlans: undefined,
		travelInsuranceTarget: 'test',
		bookingMode: undefined,
		bookable: true,
		maxPets: undefined,
		floorplan: false,
		openstreetmap: false,
		cancelUrv: false,
		lastQuote: {}
	},

	beforeMount: function () {
		this.checkOsmConsent();
	},

	created: function () {
		this.calListeners = [];
		this.priceListeners = [];
		this.pricelistListeners = [];
		this.QuoteTools = QuoteTools;

		var params = new URLSearchParams(new URL(window.location).search);
		for (let [k, v] of params) {
			if (oneIs(k, 'ref_adults', 'ref_children', 'ref_babys', 'ref_from', 'ref_till')) {
				this.bookingData[k.substring(4)] = v;
			}
		}

		var hash = window.location.hash;
		if (hash) {
			hash = hash.substring(1);

			let url = new URL(hash, 'https://www.v-office.com');
			let params = new URLSearchParams(url.search);

			for (let p of params) {
				if ((p[0] == 'children' || p[0] == 'babys') && !this.areChildrenWelcome()) {
					//ignore not welcome
				} else if (p[0] == 'petsCount' && !this.arePetsWelcome()) {
					//ignore not welcome
				} else {
					this.bookingData[p[0]] = p[1];
				}
			}
		}



		this.updatePrices();
		this.saveLastViewedUnit();

	},
	computed: {
		showBookingButtons: function () {
			return this.quote && this.quote.mandatory && !this.priceIsUpdated && isEmpty(this.quote.errors) && !this.action;
		},
		canDoNext: function () {
			return this.quote && this.quote.mandatory && !this.priceIsUpdated && isEmpty(this.quote.errors) && this.action;
		},
		periodLabel: function () {
			let label = '';
			if (this.bookingData.from) {
				label = this.voFormatDate(this.bookingData.from, 'withDay');
			}
			if (this.bookingData.till) {
				label += ' - ' + this.voFormatDate(this.bookingData.till, 'withDay');
			}

			return label;
		},
		guestLabel: function () {
			var sd = this.bookingData;
			var amount = ((sd.adults || 0) - 0) + ((sd.children || 0) - 0) + ((sd.babys || 0) - 0);
			var label = amount + ' ' + this.voMsgPl('guest.pl', amount);
			if (sd.petsCount) {
				label += ', ' + sd.petsCount + ' ' + this.voMsgPl('pet.pl', sd.petsCount);

			}
			return label;
		},
		mailto: function () {
			return `mailto:${this.emailAddress}`;
		},
		emailAddress: function () {
			return (VOFFICE.contactEmail || ['info', 'test.de']).join('@');
		},

		phoneView: function () {
			if (VOFFICE.phoneView) {
				return VOFFICE.phoneView;
			}
		},


	},
	mounted: function () {

		window.addEventListener('ccm19WidgetClosed', (event) => {
			this.checkOsmConsent();
		});

		const max = (VOFFICE.settings && VOFFICE.settings.feedbackMaxView) || 2;
		const elements = document.getElementsByClassName("fb-entry");

		if (elements.length > 0) {
			if (elements.length >= max) {
				for (let i = 0; i < max; i++) {
					elements[i].style.display = "block";
				}
				for (let i = max; i < elements.length; i++) {
					elements[i].style.display = "none";
				}
			}
		}

		const moreFeedbacksBtn = document.getElementById("moreFeedbacksBtn");
		//console.log('maxFeedbacks::', max, 'Feedbacks:', elements.length);
		if (elements.length > 0) {
			if (elements.length > max) {
				document.getElementById("moreFeedbacksBtn").style.display = "block";
			} else {
				document.getElementById("moreFeedbacksBtn").style.display = "none";
			}
		}

		if (elements.length > 0) {
			moreFeedbacksBtn.addEventListener("click", function () {
				const hiddenElements = document.querySelectorAll(
					'.fb-entry:not([style*="display: block"])'
				);

				if (hiddenElements.length <= max) {
					document.getElementById("moreFeedbacksBtn").style.display = "none";
				}
				for (let i = 0; i < max && i < hiddenElements.length; i++) {
					hiddenElements[i].style.display = "block";
				}
				const visibleElements = document.querySelectorAll(
					'.fb-entry:not([style*="display: none"])'
				);

			});
		}

		const floorplanImages = document.getElementsByClassName("floorplan");
		if (floorplanImages.length > 0) {
			this.floorplan = true;
		}
		const petsEl = document.querySelector("[data-pets-restriction]");
		if (petsEl) {
			let maxPets = petsEl.getAttribute('data-pets-restriction');
			switch (maxPets) {
			case '0':
				this.maxPets = 1;
				break;
			case '1':
				this.maxPets = 2;
				break;
			case '2':
				this.maxPets = 3;
				break;
			}
		}
		const offers = document.getElementsByClassName("unit-offer");
		const offerSection = document.getElementById("offers");
		if (offers.length < 1 && offerSection) {
			offerSection.style.display = 'none';

		}



	},
	updated: function () {
		//	$('[data-bs-toggle="tooltip"]').tooltip();
	},
	methods: {
		shareLink: function (what) {
			var link;
			var path = self.location.href;

			var el = document.getElementById('preloader');
			var text = this.voMsg('tpl.text.shareMailText') + "\n\n";
			if (what === 'email') {
				link = 'mailto:?body=';
			} else if (what === 'whatsApp') {
				link = 'https://wa.me/?text=';
			}

			var href = link + encodeURIComponent(text + path);

			window.location.href = href;
			if (el) {
				el.style.display = "none";
			}

		},
		copyToClipboard: function () {
			var copyText = document.getElementById("objUrl");
			copyText.value = self.location.href;

			copyText.select();
			copyText.setSelectionRange(0, 99999);

			navigator.clipboard.writeText(copyText.value);

			var el = document.getElementById('copylink');
			if (el) {
				el.classList.add('active');
			}
		},
		checkService: function (type) {
			if (type === 'PET') {
				if (this.petsWelcome) {
					return true;
				} else {
					return false;
				}
			}
			return true;
		},
		phoneLink: function () {
			if (VOFFICE.phoneLink) {
				var el = document.getElementById('preloader');

				window.location.href = VOFFICE.phoneLink;
				if (el) {
					el.style.display = "none";
				}
			}
		},

		checkOsmConsent: function () {
			const suffix = VOFFICE.token.substr(VOFFICE.token.length - 12);
			var cookieName = 'allowOpenstreetmap_' + suffix;
			const allowOpenstreet = this.getCookie(cookieName);
			if (allowOpenstreet) {
				this.openstreetmap = true;
			} else {
				const data = CCM.acceptedEmbeddings;
				const targetName = "OpenStreetMap";
				const entry = data.find(item => item.name === targetName);


				if (entry) {
					this.openstreetmap = true;
				} else {
					this.openstreetmap = false;
				}
			}
		},


		getCookie: function (name) {
			const nameEQ = name + "=";
			const ca = document.cookie.split(';');
			for (let i = 0; i < ca.length; i++) {
				let c = ca[i];
				while (c.charAt(0) === ' ') c = c.substring(1, c.length);
				if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
			}
			return null;
		},


		setCookie: function (name, value, days) {
			const date = new Date();
			date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
			const expires = "expires=" + date.toUTCString();
			document.cookie = name + "=" + value + ";" + expires + ";path=/";
		},


		allowOpenstreetmap: function () {
			const suffix = VOFFICE.token.substr(VOFFICE.token.length - 12);
			var cookieName = 'allowOpenstreetmap_' + suffix;
			this.setCookie(cookieName, true, VOFFICE.settings.osmCookieDays);
			this.openstreetmap = true;
		},



		showOpenstreetmap: function () {
			this.openstreetmap = true;
		},

		getDays: function (date) {
			const today = DateEx.interfaceFormat(DateEx.getToday());
			let diff = DateEx.getDays(date, today);
			return diff;

		},

		checkOffer: function (o, unitoftheday) {
			var lang = VOFFICE.lang;
			this.lang = lang;

			//	console.log('offer:::', o);
			//	console.log('voucherAngebot::', o.voucherCode);
			if (o.voucherCode) {
				//console.log('voucherAngebot::', o.name[lang]);
				if (!this.checkVoucherCode(o.voucherCode)) {
					//console.log('voucherAngebot::', o.name[lang]);
					return false;
				}
			}
			if (o.type === 'EARLYBIRD') {
				if (!this.check4EarlyBird(o)) {
					return false;
				}
			} else if (o.type === 'LASTMINUTE') {
				if (!this.check4LastMinute(o)) {
					return false;
				}
			} else if (o.type === 'UNITOFDAY') {
				if (!unitoftheday) {
					return false;
				}
			}
			if (!this.check4OfferEnd(o)) {
				return false;
			}

			return true;
		},
		checkVoucherCode: function (code) {
			if (code) {
				if (this.voucher && code === this.voucher.code) {
					return true;
				} else {
					return false;
				}

			}
		},
		check4LastMinute: function (o) {
			var timeSpan = parseInt(o.minStay);
			var tilldate = DateEx.interfaceFormat(o.tilldate);
			var fromdate = DateEx.interfaceFormat(o.fromdate);
			var minStay = parseInt(o.minStay);
			var daysToFromdate;
			if (fromdate) {
				daysToFromdate = this.getDays(fromdate);
				if (timeSpan) {
					if (daysToFromdate > timeSpan) {
						//console.log('Lastminute offer not bookable because offer start date is not reached');
						return false;
					}
				}
			}
			return true;

		},
		check4EarlyBird: function (o) {
			var timeSpan = parseInt(o.minStay);
			var tilldate = DateEx.interfaceFormat(o.tilldate);
			//var fromdate = '2023-12-01';
			var fromdate = DateEx.interfaceFormat(o.fromdate);
			var minStay = parseInt(o.minStay);

			if (tilldate) {
				var daysToTilldate = this.getDays(tilldate);
				//console.log('Days until the start of the offer::', daysToTilldate);

				if (timeSpan) {
					if (daysToTilldate <= timeSpan + 1) {
						//console.log('Earlybird offer is not bookable because the offer has already expired');
						return false;
					} else if (minStay) {
						if (daysToTilldate < (timeSpan + minStay)) {
							//console.log('Earlybird offer is not bookable because the offer has already expired, due to minimum rental period');
							return false;
						}
					}
				}
			}

			if (fromdate) {
				var daysToFromdate = this.getDays(fromdate);
				//console.log('Days until the end of the offer::', daysToFromdate);

				if (timeSpan) {
					if (daysToFromdate > timeSpan) {
						// console.log('Offer not bookable because offer start date is not reached');
						return false;
					}
				}
			}
			return true;
		},
		check4OfferEnd: function (o) {
			var minStay = parseInt(o.minStay);
			var tilldate = DateEx.interfaceFormat(o.tilldate);
			var daysToTilldate = this.getDays(tilldate);

			if (minStay && tilldate) {
				//console.log('daysToTilldate', daysToTilldate);
				if (daysToTilldate < minStay) {
					//console.log('Offer not bookable because the minimum rental period exceeds the offer end date');
					return false;
				}
			}
			return true;
		},

		showCustomModal: function (id) {
			let wh = window.innerHeight;

			// Get the modal
			const modal = document.getElementById(id);

			if (modal) {
				var dialog = modal.getElementsByClassName("modal-custom-dialog")[0];
				var content = modal.getElementsByClassName("modal-custom-content")[0];
				var body = document.body;

				if (dialog && content) {
					dialog.style.height = wh - 40 + "px";
					content.style.height = wh - 120 + "px";

					modal.style.display = "block";
					body.classList.add('modal-open');

					// When the user clicks anywhere outside of the modal, close it
					window.onclick = function (event) {
						if (event.target == modal) {
							modal.style.display = "none";
							body.classList.remove('modal-open');
						}
					};
				}
				var that = this;
				document.addEventListener('keydown', function (event) {
					that.closeModalOnEscape(id, event);
				});
			}
		},

		closeCustomModal: function (id) {
			const modal = document.getElementById(id);
			var body = document.body;
			modal.style.display = "none";
			body.classList.remove('modal-open');
			var that = this;
			document.removeEventListener('keydown', function (event) {
				that.closeModalOnEscape(id, event);
			});

		},

		closeModalOnEscape: function (id, event) {
			if (event.key === 'Escape') {
				this.closeCustomModal(id);
			}
		},

		setHeight: function () {
			const bookingDetails = document.querySelector('#booking-details');
			const height = bookingDetails.offsetHeight;
			bookingDetails.style.minHeight = height - 50 + 'px';
		},

		childrenAgesSelected: function (ages) {
			this.bookingData.childrenAges = ages;
			this.updatePrices();

		},
		scrollIntoView: function (id) {
			let el = document.getElementById(id);
			if (el) {
				el.scrollIntoView({
					behavior: 'smooth',
					block: 'start'
				});
			}

		},

		resetCal: function () {
			var el = document.getElementById("booking-details");

			if (el) {
				el.style = "";
			}
			this.bookingData.from = undefined;
			this.bookingData.till = undefined;
			this.needTill = undefined;
			this.action = undefined;
			this.updatePrices();
			this.scrollIntoView('calendarSection');

		},

		openSection: function (id) {
			let that = this;
			let allOpenSections = document.querySelectorAll(".showmore.show");
			let toOpenElement = document.getElementById('showmore-' + id);
			let toOpenElementBtn = document.getElementById('showmore-' + id + '-btn');
			let toOpenElementTitle = document.getElementById('showmore-' + id + '-title');
			let scrollToElement = document.getElementById(id);
			let unitNavBtn = document.getElementById('unitnav-' + id);
			let allUnitNavBtn = document.querySelectorAll(".unit-nav-btn");
			if (allOpenSections.length > 0) {
				allOpenSections.forEach(function (element) {
					if (element !== toOpenElement) {
						that.showMore(element.id, element.id + '-btn', element.id + '-title');
					}
				});
			}
			if (allUnitNavBtn) {
				allUnitNavBtn.forEach(function (element) {
					element.classList.remove('active');
				});
			}
			if (unitNavBtn) {
				unitNavBtn.classList.add('active');
			}
			if (scrollToElement) {
				if (toOpenElement && !toOpenElement.classList.contains("show")) {
					toOpenElement.classList.add('show');
					toOpenElementTitle.classList.add('show');
					toOpenElementBtn.innerHTML = this.voMsg("tpl.unit.props.show.less") + ' <i class="fa-sharp fa-light fa-chevron-up"></i>';
				}
				this.scrollIntoView(id);

			}
		},
		showMore: function (id, scroll = false) {

			let el = document.getElementById('showmore-' + id);
			let el2 = document.getElementById('showmore-' + id + '-btn');
			let el3 = document.getElementById('showmore-' + id + '-title');
			var windoWith = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
			var body = document.body;


			/*	var allTitles = document.getElementsByClassName('section-title');
				var allContents = document.getElementsByClassName('showmore');

				// Für alle Elemente mit der Klasse 'section-title'
				for (var i = 0; i < allTitles.length; i++) {
					// Entfernen Sie die Klasse 'show'
					allTitles[i].classList.remove('show');
				}

				// Für alle Elemente mit der Klasse 'showmore'
				for (var j = 0; j < allContents.length; j++) {
					// Entfernen Sie die Klasse 'show'
					allContents[j].classList.remove('show');
				} */

			if (el && el2) {
				if (el.classList.contains("show")) {
					el.classList.remove('show');
					el2.innerHTML = this.voMsg("tpl.unit.props.show.more") + ' <i class="fa-sharp fa-light fa-chevron-down"></i>';
					if (scroll) {
						//console.log('scroll', id);
						this.scrollIntoView(id);
					}
				} else {
					el.classList.add('show');
					el2.innerHTML = this.voMsg("tpl.unit.props.show.less") + ' <i class="fa-sharp fa-light fa-chevron-up"></i>';

				}
			}
			if (el3) {
				if (el3.classList.contains("show")) {
					el3.classList.remove('show');
					body.classList.remove('modal-open');
				} else {
					el3.classList.add('show');
					if (windoWith <= 767) {
						body.classList.add('modal-open');
					}
				}
			}
		},
		closeMobileSidebar: function (id) {
			let el = document.getElementById(id);
			var body = document.body;
			if (el) {
				el.classList.remove('show');
				body.classList.remove('modal-open');
			}
		},
		showMobileSidebar: function (id) {
			let el = document.getElementById(id);
			var body = document.body;
			if (el) {
				el.classList.add('show');
				body.classList.add('modal-open');
			}

		},
		countUnitImages: function (id) {
			let numb = document.querySelectorAll('#' + id + ' .unit-picture').length;
			return numb;

		},
		showBookNow: function (entries) {
			var el2 = document.getElementById("book-now");

			if (el2) {
				if (this.action !== 'booking') {
					if (!entries[0].isIntersecting) {
						//	console.log('nicht sichtbar!');
						el2.classList.add("is-visible");
					}
					if (entries[0].isIntersecting) {
						//	console.log('sichtbar!');
						el2.classList.remove("is-visible");
					}
				}
				if (this.action === 'booking') {
					el2.classList.remove("is-visible");
				}

			}

		},

		getAction: function () {
			return this.action;
		},
		saveLastViewedUnit: function () {
			var unitId = this.getUnitId();
			var data = this.bookingData;
			var units = [];
			var storedUnits = {};
			var suffix = VOFFICE.token.substr(VOFFICE.token.length - 12);
			var savedUnits = JSON.parse(localStorage.getItem('lastVisitedUnits_' + suffix));
			var count = 0;
			if (savedUnits) {
				count = Object.keys(savedUnits).length;
			}
			units[0] = {
				'_id': unitId,
				'till': data.till,
				'from': data.from,
				'adults': data.adults,
				'children': data.children,
				'babys': data.babys
			};

			if (count > 0) {

				for (var index = 0; index < count; index++) {
					if (parseInt(savedUnits[index]._id) === parseInt(unitId)) {
						//console.log('Objekt gibt es schon');
					} else {
						units[index + 1] = savedUnits[index];
					}
				}

			}
			var i = 0;
			const maxLastView = (VOFFICE.settings && VOFFICE.settings.maxLastView) || 5;
			for (var _unit in units) {
				if (i < maxLastView) {
					storedUnits[i] = units[_unit];
				}
				i++;
			}

			var strJson = JSON.stringify(storedUnits);

			localStorage.setItem('lastVisitedUnits_' + suffix, JSON.stringify(storedUnits));

		},
		checkBrowserLanguage: function () {
			if (navigator.language && (navigator.language != 'de-DE' && navigator.language != 'de')) {
				return true;
			}
			return false;
		},
		checkVOFFICElang: function () {
			if (VOFFICE.lang === 'de') {
				return true;
			}
			return false;
		},
		selectTravelInsurance: function (plan) {
			this.selectedTravelInsurance = plan;
		},


		getBookingMode: function () {
			if (!this.bookingMode) {
				let el = document.querySelector("[data-unit-bookingmode]");
				if (el) {
					this.bookingMode = el.getAttribute('data-unit-bookingmode');
				}
			}
			return this.bookingMode;
		},


		getTravelInsurancePlans: function () {

			//console.log('getTravelInsurancePlans');
			var data = this.bookingData;
			var canUseSamePlan = false;
			var prevSelectedPlan = this.selectedTravelInsurance;
			var bookingMode = this.getBookingMode();

			//console.log('quote.travelInsuranceIsSetup::', this.quote.travelInsuranceIsSetup, 'bookingMode::', bookingMode);


			if (data.from && data.till && this.quote && this.quote.travelInsuranceIsSetup && bookingMode === "DIRECTLY") {


				var req = {
					fromdate: DateEx.interfaceFormat(data.from),
					tilldate: DateEx.interfaceFormat(data.till),
					adults: data.adults,
					children: data.children,
					babys: data.babys,
					unit: this.getUnitId(),
					totalBookingAmount: QuoteTools.getTotal(this.quote, 'all')
				};
				if (VOFFICE.travelInsurance && VOFFICE.travelInsurance.forceTravelInsuranceMode) {
					req.forceTravelInsuranceMode = VOFFICE.travelInsurance.forceTravelInsuranceMode;
				}
				//console.log('***totalBookingAmount', req.totalBookingAmount);
				doReq('getTravelInsurancePlans', req)
					.then((res) => {
						this.travelInsuranceTarget = res.target ? res.target : 'test';
						var ix;


						//console.log('getTravelInsurancePlans', res, this.travelInsuranceTarget);
						if (this.travelInsurancePlans) {
							canUseSamePlan = true;

							for (ix in res.plans) {
								//console.log(this.travelInsurancePlans[ix].amount);

								if (!this.travelInsurancePlans[ix]) {
									canUseSamePlan = false;
								} else if (res.plans[ix].planid != this.travelInsurancePlans[ix].planid) {
									canUseSamePlan = false;
								} else if (res.plans[ix].planCost.amount != this.travelInsurancePlans[ix].planCost.amount) {
									canUseSamePlan = false;
								}
							}
						}
						if (!canUseSamePlan) {
							if (prevSelectedPlan) {
								this.selectedTravelInsurance = null;
							}
							this.travelInsurancePlans = res.plans;


							if (prevSelectedPlan) {
								for (ix in travelInsurancePlans) {
									if (this.travelInsurancePlans[ix].name == prevSelectedPlan.name) {
										this.selectedTravelInsurance = this.travelInsurancePlans[ix];
									}
								}
							}
						}
					});
			}
		},

		togglePropClass: function () {
			this.showAllProps = !this.showAllProps;
			this.showFullDesc = !this.showFullDesc;
		},

		toggleTextClass: function () {
			this.showFullTexts = !this.showFullTexts;
		},

		addLine: function (quote, sl) {
			if (sl.service.type === 'PET') {
				if (this.bookingData.petsCount >= this.maxPets) {
					VOFFICE.maxAmounts[sl.service.id] = this.maxPets;
					return;
				}
			}
			QuoteTools.addLine(quote, sl);
			if (sl.service.type === 'PET') {
				this.bookingData.petsCount = sl.amount;
			}
			this.$forceUpdate();
			this.getTravelInsurancePlans();
			this.saveLastQuote(this.quote.mandatory);
		},
		removeLine: function (quote, sl) {
			QuoteTools.removeLine(quote, sl);
			if (sl.service.type === 'PET') {
				this.bookingData.petsCount = sl.amount;
			}
			this.$forceUpdate();
			this.getTravelInsurancePlans();
			this.saveLastQuote(this.quote.mandatory);
		},
		saveLastQuote: function (quote) {
			this.lastQuote = quote;
			//console.log('this.lastQuote::', this.lastQuote);

		},
		addBookedServices: function () {
			if (this.lastQuote.length > 1) {
				this.lastQuote.forEach(lsl => {
					if (lsl.booked && lsl.optional) {
						var amount = lsl.amount;
						var id = lsl.service.id;
						this.quote.optional.forEach(sl => {
							if (sl.service.id === id) {
								var serviceLine = sl;
								for (var i = 0; i < amount; i++) {
									this.addLine(this.quote, serviceLine);
								}
							}
						});
					}
				});
			}
		},
		clearPrices: function () {
			//console.log('clearPrices:');
			this.quote = {};
			this.summary = undefined;
			this.preview = undefined;
			this.previewQuote = undefined;
		},
		setChildrenOption: function (el, valueToSelect) {
			for (let i = 0; i < el.options.length; i++) {
				if (el.options[i].value === valueToSelect) {
					//console.log('Wert gefunden:', valueToSelect);
					// Wenn der Wert übereinstimmt, setzen Sie den Index, um die Option als ausgewählt festzulegen.
					el.selectedIndex = i;
					break; // Brechen Sie die Schleife ab, sobald die Option gefunden wurde.
				}
			}
		},
		isElementInViewport: function (element) {
			// Holen Sie sich die Position und die Abmessungen des Elements
			const rect = element.getBoundingClientRect();

			// Überprüfen Sie, ob das Element im sichtbaren Bereich des Viewports liegt
			return (
				rect.top >= 0 &&
				rect.left >= 0 &&
				rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
				rect.right <= (window.innerWidth || document.documentElement.clientWidth)
			);
		},
		updatePrices: function (scrollToPricesCalc) {
			var data = this.bookingData;
			this.error = undefined;

			//console.log('data::', data);

			if (VOFFICE.settings.childrenAges) {

				let childrenAgeArray = [];
				let valueToSelect;

				if (data.children > 0) {

					if (data && data.childrenAges) {

						childrenAgeArray = data.childrenAges.split(",");
						//console.log('childrenAgeArray:', childrenAgeArray);
						var element;

						for (var i = 1; i <= childrenAgeArray.length; i++) {
							element = document.getElementById('sel' + i);
							//console.log('i:', i);
							//console.log('Element2::', element, 'index::', i, 'value::', element.value);
							if (element) {
								valueToSelect = childrenAgeArray[i - 1];
								this.setChildrenOption(element, valueToSelect);
							}
						}
					}

					document.querySelectorAll(".childrenagesel").forEach((el, i) => {
						//	console.log('Element::', el, 'index::', i, 'value::', el.value);

						if (el.value === 'undefined') {
							this.bookable = false;
							el.classList.add('warn');
						}
						if (el && el.value !== 'undefined') {
							el.classList.remove('warn');
						}

					});

					if (data.childrenAges && !data.childrenAges.includes('undefined') && data.childrenAges.split(",").length === parseInt(data.children)) {
						this.bookable = true;
					}

					if (!this.bookable) {
						document.querySelectorAll(".children-age-notice").forEach(e => e.style.display = 'block');
					} else {
						document.querySelectorAll(".children-age-notice").forEach(e => e.style.display = 'none');
					}

				} else {
					this.bookable = true;
				}
			}

			if (data.from && data.till) {

				this.priceIsUpdated = true;
				this.quote = {};


				var req = {
					fromdate: DateEx.interfaceFormat(data.from),
					tilldate: DateEx.interfaceFormat(data.till),
					adults: data.adults,
					children: data.children,
					babys: data.babys,
					petsCount: data.petsCount,
					unit: this.getUnitId()
				};

				req.includeServiceLimits = true;

				if (VOFFICE.quoteOptions) {
					Object.assign(req, VOFFICE.quoteOptions);
				}

				var voucher = getVoucher();

				if (voucher) {
					var code = localStorage.getItem("voucher_code");
					if (code === voucher.code) {
						req.voucherCode = voucher.code;
					}
				}

				if (VOFFICE.abtest) {
					req.abtest = VOFFICE.abtest;
				}

				if (data.childrenAges) {
					req.childrenAges = data.childrenAges;
				}

				if (VOFFICE.travelInsurance) {
					if (VOFFICE.travelInsurance.checkTravelInsuranceIsSetup) {
						req.checkTravelInsuranceIsSetup = true;

						if (VOFFICE.travelInsurance.forceTravelInsuranceMode) {
							req.forceTravelInsuranceMode = VOFFICE.travelInsurance.forceTravelInsuranceMode;
						}
					}
				}

				if (window.scrollY > 0) {
					this.scrollToPriceCalc();
				}


				if (this.bookable) {

					doReq('quotePrices', req)
						.then((res) => {

							this.quote = res.quote;
							this.priceIsUpdated = false;
							this.quote.optional.forEach(sl => {
								sl.optional = true;
								sl.amount = null;
								this.QuoteTools.updateTotal(sl);
							});
							this.quote.onsiteOptional.forEach(sl => {
								sl.onsiteOptional = true;
								sl.amount = null;
								this.QuoteTools.updateTotal(sl);
							});
							this.quote.usage.forEach(sl => {
								sl.usage = true;
								sl.amount = null;
								this.QuoteTools.updateTotal(sl);
							});

							if (res.quote.mandatory) {
								this.summary = find(res.quote.mandatory, function (sl) {
									return sl.service.type === 'SUMMARY';
								});

								this.preview = filter(res.quote.mandatory, function (sl) {
									return !VOFFICE.hideTypeOnPricePreview || VOFFICE.hideTypeOnPricePreview.indexOf(sl.service.type) == -1;
								});

								if (!this.preview || this.preview.length < 2) {
									this.preview = undefined;
									this.previewQuote = undefined;
								} else {
									this.previewQuote = {
										mandatory: this.preview,
										hasDiscounts: this.quote.hasDiscounts
									};
								}

							}

							this.getTravelInsurancePlans();

							fireQuote(this.getCart());
							this.goAction('booking');


							setTimeout(this.setHeight, 500);

							this.addBookedServices();

						})
						.catch(e => {
							this.priceIsUpdated = false;
							this.error = e;
						});

				}


			}

		},
		getCart: function () {
			// workarround 
			this.quote.selectedTravelInsurance = this.selectedTravelInsurance;
			this.quote.travelInsuranceTarget = this.travelInsuranceTarget;

			return {
				action: this.action,
				quote: this.quote,
				booking: Object.assign({}, this.bookingData),
				unitId: this.getUnitId()
			};
		},

		goAction: function (action) {
			this.action = action;
			this.showPriceDetails = true;
			fireUnitAction(this.getCart());
		},

		putInCart: function (action) {
			if (action == 'option') {
				this.selectedTravelInsurance = null;
				this.cancelUrv = true;
			} else {
				this.cancelUrv = false;
			}

			//console.log('this.cancelURV', this.cancelURV);

			this.action = action;

			// @todo pruefen, ob eine Versicherung gewählt wurde
			if (this.quote.travelInsuranceIsSetup) {

				if (this.selectedTravelInsurance == null) {
					//console.log("Versicherung wählen!");
				}
			}
			this.step = 'booking';

			var similarUnits = document.getElementById("similarunits");
			if (similarUnits) {
				similarUnits.style.display = "none";
			}
			var lastViewedUnits = document.getElementById("lastviewed-units");
			if (lastViewedUnits) {
				lastViewedUnits.style.display = "none";
			}
			fireCheckout(this.getCart());
			setTimeout(() => {
				window.scrollTo(0, 0);
			}, 100);


		},
		cancelBooking: function () {
			this.step = undefined;
			this.$nextTick(function () {
				this.scrollToPriceCalc();
			});

		},
		cancel: function () {
			this.bookingData.from = undefined;
			this.bookingData.till = undefined;
			this.needTill = undefined;
			this.action = undefined;
			this.updatePrices();
			var similarUnits = document.getElementById("similar-units");
			if (similarUnits) {
				similarUnits.style.display = "block";
			}
			this.showPriceDetails = true;

			this.scrollToPriceCalc();


		},


		getUnitId: function () {
			if (!this.unitId) {
				let el = document.querySelector("[data-unit-id]");
				if (el) {
					this.unitId = parseInt(el.getAttribute('data-unit-id'), 10);
				}
			}
			return this.unitId;
		},
		areChildrenWelcome: function () {
			if (this.childrenWelcome == undefined) {
				let el = document.querySelector("[data-children-welcome]");
				if (el) {
					this.childrenWelcome = el.getAttribute('data-children-welcome') == '1';
				} else {
					this.childrenWelcome = false;
				}
			}
			return this.childrenWelcome;
		},
		arePetsWelcome: function () {
			if (this.petsWelcome == undefined) {
				let el = document.querySelector("[data-pets-welcome]");
				if (el) {
					this.petsWelcome = el.getAttribute('data-pets-welcome') == '1';
				} else {
					this.petsWelcome = false;
				}
			}
			return this.petsWelcome;
		},
		getMaxValues: function () {
			if (this.maxPets > 0) {
				var object = {
					'petsCount': this.maxPets
				};
				return object;
			}

		},

		getCal: function (done) {
			if (this.cal) {
				//console.log('kalendar schon da');
				done(this.cal);
			} else {
				this.calListeners.push(done);

				if (this.calListeners.length === 1) {

					var req = {
						unit: this.getUnitId()
					};

					doReq('getCal', req)
						.then((res) => {
							this.cal = res.cal;

							for (let l of this.calListeners) {
								l(res.cal);
							}
							this.calListeners.length = 0;
						});

				}
			}

		},
		getPrices: function (done) {
			if (this.prices) {
				//console.log('Prices schon da');
				done(this.prices);
			} else {
				this.priceListeners.push(done);

				if (this.priceListeners.length === 1) {


					var req = {
						id: this.getUnitId()
					};

					var params = {
						'noCache': false
					};

					doReq('getPricelist', req, params)
						.then((res) => {
							this.prices = res.prices;
							for (let l of this.priceListeners) {
								l(res.prices);
							}
							this.priceListeners.length = 0;
						});


				}
			}
		},

		scrollToPriceCalc: function () {
			//console.log("scrollToTravelData");
			this.scrollIntoView('travel-data');
		},
		showPricelist() {
			// pricelist-container
			this.pricelistVisible = true;
		},
		hidePricelist() {
			this.pricelistVisible = false;
		}
	},
	watch: {
		'bookingData.from': function () {
			if (!this.bookingData.till) {
				this.clearPrices();
			}
			//this.getTravelInsurancePlans();

		},
		/*	'bookingData.till': function () {
				//this.getTravelInsurancePlans();
			}*/
	}
};